<template>
  <div class="data-box">
    <van-nav-bar
        title="车辆清洁"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-head">
      请遵守车辆清洁消费标准与单次消费限额<br/>
      请配合提供方工作人员的指挥和要求
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">车辆服务负责人</span>
          <span class="span-name align-right">{{ personInfo.chargeName ? personInfo.chargeName : "无" }}</span>
          <a :href="'tel:' + personInfo.chargePhone" class="iconfont icon-dianhua1" v-show="personInfo.chargePhone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in liveKeyValueList" :key="index">
          <span class="span-key">{{ item.key }}</span>
          <span class="span-value" :style="{width: item.hasIcon ? '60%' : '',}">{{ item.value ? item.value : "无" }}</span>
          <a :href="'tel:' + item.phone" class="iconfont icon-dianhua1" v-show="item.hasIcon&&item.phone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-code">
          <vue-qr :text="codeUrl" :size="200"></vue-qr>
        </div>
        <div class="body-div-prompt align-center">
          消费车辆清洁时请向提供方工作人员展示二维码<br/>
          进行车辆清洁消费登记，请勿自行扫码操作
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import {
  getWxCode
} from "@/api/index";

export default {
  name: "parClear",
  data() {
    return {
      personInfo: {},
      liveKeyValueList: [
        { key: "消费备注", value: "", hasIcon: false},
        { key: "单次限额", value: "", hasIcon: false},
        { key: "提供场地", value: "", hasIcon: false},
        { key: "联系方式", value: "", phone: "", hasIcon: true},
      ],
      codeUrl: ""
    }
  },
  components: {
    vueQr
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let activitySets = that.$store.getters.getOPDetails.activitySets;
      activitySets.forEach(item => {
        if(item.relateType === 17){
          that.personInfo = item;
        }
        if(item.relateType === 6){
          that.liveKeyValueList[0].value = item.noteNameDateStop;
          that.liveKeyValueList[1].value = item.checkTypeNumArticle + "元/次";
          that.liveKeyValueList[2].value = item.chargeName;
        }
        if(item.relateType === 11){
          that.liveKeyValueList[3].value = item.chargeName;
          that.liveKeyValueList[3].phone = item.chargePhone;
        }
      })
      that.getWxCode();
    },
    getWxCode(){
      let that = this;
      that.codeUrl = that.$store.state.baseURL + "#/parClear_cost?id=" + that.$store.getters.getActObjId;
     /* let url = that.$store.state.baseURL + "#/parClear_cost"
      let params ={
        app_id: that.$store.state.app_id,
        redirect_uri: that.$tool.toURLEncode(url),
        state: 1
      };
      that.codeUrl = getWxCode(params);*/
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>